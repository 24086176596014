import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Header = styled.header`
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0.5rem auto;

  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 1em;
  }
  padding: 0 2.5em;

  ul {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    // align-items: flex-end;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-of-type {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: black;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
    // text-transform: uppercase;
    transition: all 0.2s;
    // border-bottom: 2px solid ${props => props.theme.colors.text};
    border-bottom: 0px;
    &:hover {
      color: black;
    }
  }
`

const Logo = styled(Link)`
  width: 50%;
`

const activeLinkStyle = {
  // borderBottom: '0px',
  borderBottom: '2px solid'
}

// export const query = graphql`
//   query {
//     fileName: file(relativePath: { eq: "logo19@2x.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 400, maxHeight: 250) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

const Menu = () => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Header>
      <Nav>
        <ul>
          {/* <li>
            <Logo to="/">
              Imaiginaire
            </Logo>
          </li> */}
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
